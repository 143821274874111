@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.circle{
  border-radius: 50%;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  
}
*{
  font-family: "Raleway";
  /* max-width: 1920px;
  max-height: 1080px;
  margin:auto; */
}

body {
  max-width: 1920px;
  max-height: 1080px;
  margin:auto;
}

nav{
  max-width: 1920px !important;
  margin: auto;
}